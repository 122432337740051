<template>
	<page-container :page="page">
		<heading-image
			v-if="page && page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #hero-content>
				<div class="row heading-row">
					<div class="columns column9">
						<h2>{{ defaults[locale].homepage.headingTitle }}</h2>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:title-right="page.titleRight"
			:subtitle="page.subtitle"
			:content="page.content"
			:intro-image="page.introImage"
			:intro-image-alt="page.introImageAlt"
			:intro-image-webp="page.introImageWebp"
			:intro-image-portrait="page.introImagePortrait"
			:intro-image-portrait-alt="page.introImagePortraitAlt"
			:intro-image-portrait-webp="page.introImagePortraitWebp"
		/>

		<section class="main-content usp">
			<div class="row align-center">
				<div
					v-for="(usp, index) in defaults[locale].homepage.uniqueSellingPoints"
					:key="index"
					class="columns column4"
				>
					<font-awesome-icon :icon="usp.icon" size="1x" />
					<div v-parse-links v-html="usp.content" />
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.sectionBenefitsTitle" class="main-content booking-benefits">
			<div class="row">
				<div class="columns column4">
					<div class="content">
						<h3 class="subtitle">{{ defaults[locale].homepage.sectionBenefitsSubtitle }}</h3>
						<h3>{{ defaults[locale].homepage.sectionBenefitsTitle }}</h3>
						<ul>
							<li v-for="(benefit, index) in defaults[locale].homepage.benefit" :key="index">
								<font-awesome-icon :icon="`far fa-check-circle`" size="1x" />
								<span>{{ benefit.content }}</span>
							</li>
						</ul>
						<book-button class="button">
							{{ $t('bookNow') }}
						</book-button>
					</div>
				</div>
			</div>
			<div class="bg-image">
				<picture>
					<source
						v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
						:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
						type="image/webp"
					/>
					<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
					<img
						class="image"
						:src="defaults[locale].homepage.sectionBenefitsImage"
						:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
						loading="lazy"
					/>
				</picture>
			</div>
		</section>

		<promoblocks v-if="promoblocksData.length" :items="promoblocksData" :amount="2">
			<template #header>
				<div v-if="defaults[locale].homepage.sectionPromotionContent.length" class="columns column6 align-left">
					<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
				</div>
				<div v-else class="columns column12 align-center">
					<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
				</div>
				<div v-if="defaults[locale].homepage.sectionPromotionContent.length" class="columns column6 align-left">
					<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
				</div>
			</template>
		</promoblocks>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" class="index">
			<template #header>
				<div class="columns column12 align-center">
					<h2>{{ page.contentBlockContent }}</h2>
				</div>
			</template>
		</contentblocks>

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
h1 {
	margin: 25px 0;
}

#intro {
	.column6 {
		align-self: center;
	}
}

.booking-benefits {
	position: relative;

	.bg-image {
		z-index: 1;
		position: absolute;
		inset: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.row {
		position: relative;
		z-index: 2;

		.content {
			background: var(--primary-background-color);
			color: var(--primary-text-color);
			margin: 50px 0;
		}

		ul {
			display: flex;
			flex-flow: row wrap;
			list-style: none;
			padding: 0;

			li {
				width: 100%;
				margin: 3px 0;
				letter-spacing: 1px;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: flex-start;

				svg {
					color: var(--primary-color);
					margin: 0 10px 0 0;
					font-size: 22px;
				}
			}
		}
	}
}

.usp {
	background: var(--footer-background-color);
	color: var(--primary-background-color);
	padding: 175px 30px 100px;
	margin-top: -100px;
	position: relative;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;

		.column4 {
			border: 1px solid var(--primary-color);
			padding: 60px 40px;
			width: 31%;
		}
	}

	h3,
	:deep(h3) {
		font-weight: 400;
		font-variation-settings: 'wght' 400;
	}

	svg {
		color: var(--primary-color);
		font-size: 40px;
		margin: 5px 0 20px;
	}
}

.promoblocks-header {
	h2 {
		margin: 0 auto 40px;
	}
}

.heading-row {
	position: absolute;
	inset: 50% 0 auto;
	margin: 0 auto;
	transform: translateY(-50%);
	z-index: 10;
	text-align: center;
	max-width: 1000px;

	.columns {
		margin: 0 auto;
		float: none;
	}

	h2,
	:deep(h2) {
		color: #fff;
		font-size: 56px;
	}
}

@media (max-width: 960px) {
	.usp .row .column4 {
		width: 100%;
		margin: 20px auto;
	}

	.column6 {
		padding: 25px;
	}

	.heading-row {
		h2,
		:deep(h2) {
			font-size: 46px;
		}
	}
}

@media (max-width: 800px) {
	.usp {
		padding: 175px 20px 100px;
	}

	.heading-row {
		h2,
		:deep(h2) {
			font-size: 38px;
		}
	}
}

@media (max-width: 600px) {
	.usp .row .column4 {
		padding: 40px 20px;
	}
}
</style>
